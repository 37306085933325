@import "../../scss/color";
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400&display=swap");

footer {
  background-color: $nord1;
  padding-bottom: 50px;
}
.footer__separator {
  width: 100%;
  height: auto;
  position: relative;
  background-color: #292e39;
  display: block;
  user-select: none;
  pointer-events: none;
  overflow: hidden;

  &_wave1 {
    fill: rgb(55, 61, 76);
  }

  &_wave2 {
    fill: rgb(57, 64, 79);
  }

  &_wave3 {
    fill: rgb(59, 66, 82);
  }
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__content__me {
  display: flex;
  margin-top: 50px;
  margin-bottom: 2px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 10px;
  text-align: center;

  .name {
    color: $nord6;
    font-size: 30px;
    font-weight: 200;
    font-style: italic;
    margin-left: 0.5em;
  }
  @media screen and (max-width: 337px) {
    .name {
      margin: 0.5em;
    }
  }
}

.footer__content__social {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  a {
    text-decoration: none;
    cursor: pointer;
    color: $nord6;
    margin: 10px;
  }
  a:active,
  a:focus,
  a:hover,
  a:visited {
    outline: currentcolor none medium;
  }

  .footer__icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    transition: fill 200ms ease-in-out 0s;
    &:hover {
      fill: rgb(136, 192, 208);
    }
  }
}

.footer {
  background-color: $nord1;
  &__lang {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
  }
  &__locale {
    white-space: nowrap;
    margin-top: 0px;
    z-index: 3000;
    font-size: 15px;
    color: #ffffff;
    font-weight: 500;
    border: none;
    direction: rtl;
    position: relative;
    border-radius: 6px;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s;
    user-select: none;
    text-align: center;
    min-width: 90px;
    &:hover,
    &-active {
      border: 1px solid $nord4;
      background-color: $nord2;
    }

    &__dropdown {
      border-radius: 6px;
      background-color: $nord0;
      border: 0px solid $nord4;
      overflow: hidden;
      position: absolute;
      bottom: 40px;
      right: 0px;
      text-align: left;
      box-sizing: border-box;
      transition: all 0.2s ease-in-out;
      max-height: 0px;
      padding: 0 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);

      &-show {
        border-width: 1px;
        padding: 6px;
        max-height: 140px;
      }
      &__item {
        width: 100%;
        border-radius: 4px;
        white-space: nowrap;
        text-align: left;
        padding: 8px 14px;
        box-sizing: border-box;
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        &:hover {
          background-color: $nord10;
        }
      }
    }
  }

  &__copyright {
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $nord6;
    font-size: 18px;

    font-weight: 300;
    line-height: 20px;

    &__container {
      border-top: 1px solid $nord4;
      border-bottom: 1px solid $nord4;
      padding: 30px;
      .notAtAll {
        font-weight: 500;
        font-style: italic;
        font-weight: bold;
      }

      div:last-child {
        margin-top: 30px;
        font-size: 15px;
      }
      @media screen and (max-width: 350px) {
        padding: 30px 15px;
      }

      .source {
        margin-top: 5px;
        a {
          display: inline-flex;
          justify-content: center;
          text-decoration: none;
          cursor: pointer;
          color: $nord6;
          svg {
            margin-left: 2px;
            font-size: 15px;
            margin-top: 2px;
          }
          &:hover {
            transition: all 0.3s ease-in-out 0s;
            color: rgb(136, 192, 208);
            text-decoration: underline;
          }
        }
        a:active,
        a:focus,
        a:hover,
        a:visited {
          outline: currentcolor none medium;
        }
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    a {
      border-left: 1px solid #292e39;
      padding: 2px 7px;
    }

    padding: 0px 5px;
    div:first-child {
      a:first-child {
        border-left: none;
      }
    }
    @media screen and (max-width: 393px) {
      a {
        padding: 5px 7px;
        text-align: center;
        display: block;
        border-left: 1px solid #292e39;
        border: 1px solid #292e39;
        transition: none !important;
      }
      div:first-child {
        a:first-child {
          border-left: 1px solid #292e39;
        }
      }
    }
  }

  &__works__licence {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__code {
      border-radius: 13px;
      background-color: #292e39;
      padding: 15px;
      margin: 10px;
      font-family: "Fira Code", monospace;
    }
  }
}
