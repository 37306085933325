@import "../../scss/color";

.notFound {
  background-color: #292e39;
  &__container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__illustration {
    width: 20em;
    &__cloudTop {
      animation: 4000ms ease-in-out 1200ms infinite alternate none running cloud;
    }
    &__cloudBottom {
      animation: 4000ms ease-in-out 0s infinite alternate none running cloud;
    }
  }

  &__description {
    margin: 10px;
    margin-top: 20px;
    text-align: center;
    h3 {
      margin-top: 0px;
      margin-bottom: 0.5em;
      font-weight: 400;
      font-size: 1.60181em;
      color: $nord4;
    }
    p {
      margin-bottom: 1em;
      margin-top: 1em;
      color: $nord5;
      font-weight: 500;
    }
  }
}

@keyframes cloud {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(4%, 0px, 0px);
  }
}
