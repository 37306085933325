@import "../../scss/color";

.redirect {
  display: flex;
  flex-direction: column;
  background-color: #292e39;
  align-items: center;
  justify-content: center;

  &__spinner {
    margin: 10px;
  }

  h1 {
    color: $nord6;
    font-size: 30px;
    font-style: italic;
  }
}
