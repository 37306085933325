@keyframes hover-feedback {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}
