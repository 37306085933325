@import "../../scss/color";
@import "../../scss/animation";

.project__tile {
  &:visited,
  &:link {
    color: $nord6;
    text-decoration: none;
    outline: none;
  }

  margin: 10px;
  background-color: $nord3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  max-height: 100%;
  justify-content: space-between;

  &__main {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: space-around;

    &__title {
      h3 {
        margin-bottom: 0px;
        text-align: center;
      }
      p {
        margin-top: 0px;
        text-align: center;
        font-size: 13px;
        font-weight: 300;
      }
    }

    &__img img {
      height: 5em;
      width: auto;
      object-fit: contain;
      margin-left: 5px;
    }
  }

  &__description {
    text-align: justify;
    margin: 5px;
  }

  &__builtWith {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
      font-size: 15px;
      font-weight: 300;
      //   font-style: italic;
      text-align: center;
      margin: 0px;
    }

    &__icon {
      margin: 5px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      svg {
        margin: 5px;
        height: 1.5em;
        width: 1.5em;
      }
    }
  }
  &:hover {
    animation: hover-feedback 0.3s;
  }
}
